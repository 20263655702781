define('editorium/services/ui', ['exports', 'ember-service'], function (exports, _emberService) {
  exports['default'] = _emberService['default'].extend({
    isCardsCollapsed: false,

    actions: {
      collapseCards: function collapseCards() {
        this.set('isCardsCollapsed', true);
      },

      expandCards: function expandCards() {
        this.set('isCardsCollapsed', false);
      }
    }
  });
});