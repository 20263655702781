define('editorium/components/wufoo-form/component', ['exports', 'ember-component', 'rsvp'], function (exports, _emberComponent, _rsvp) {

  var SCRIPT_URL = (document.location.protocol === 'https:' ? 'https://' : 'http://') + 'www.wufoo.com/scripts/embed/form.js';

  exports['default'] = _emberComponent['default'].extend({

    // Wufoo config (configurable as component attrs)
    formHash: null,
    userName: null,
    autoResize: true,
    height: '175',
    async: true,
    host: 'wufoo.com',
    header: 'show',
    ssl: true,

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);

      this.insertScriptTag().then(function () {
        _this.initWufooForm();
      });
    },

    initWufooForm: function initWufooForm() {
      var options = this.getProperties('userName', 'formHash', 'autoResize', 'height', 'async', 'host', 'header', 'ssl');

      if (!options.formHash || !options.userName) {
        console.warn('WuFoo forms must have both `userName` and `formHash` fields.');
        return;
      }

      var form = new window.WufooForm();
      form.initialize(options);
      form.display();
    },

    insertScriptTag: function insertScriptTag() {
      return new _rsvp['default'].Promise(function (resolve, _reject) {
        if (window.WufooForm) {
          return resolve();
        }

        var script = document.createElement('script');
        script.src = SCRIPT_URL;
        script.onload = function () {
          resolve();
        };

        document.body.appendChild(script);
      });
    }
  });
});