define('editorium/components/cards-list/component', ['exports', 'ember', 'ember-computed'], function (exports, _ember, _emberComputed) {
  var Component = _ember['default'].Component;

  var injectService = _ember['default'].inject.service;

  /**
    Editorium Editor Component
  
    @module editorium
    @class EditoriumEditor
    @extends Ember.Component
  */
  exports['default'] = Component.extend({

    store: injectService(),
    ui: injectService(),

    isEmpty: (0, _emberComputed.not)('store.model.length'),

    actions: {

      undo: function undo() {
        this.get('store').undo();
      },

      redo: function redo() {
        this.get('store').redo();
      },

      save: function save(item) {
        var store = this.get('store');
        store.update(item);
        store.commit();
      },

      /**
        @method [action] reorderItems
        @param newOrder {Array} The new model with the updated order.
      */
      reorderItems: function reorderItems(newOrder) {
        this.get('store').reorderItems(newOrder);
      },

      /**
        @method [action] removeItem
        @param item {Object}
      */
      removeItem: function removeItem(item) {
        this.get('service').removeItem(item);
        this.sendAction('on-remove-item', item);
      },

      /**
        @method [action] addItem
        @param type {String}
      */
      addItem: function addItem(type) {
        this.get('service').addItem(type);
      }
    }
  });
});