define('editorium/services/store', ['exports', 'ember-service', 'ember-evented', 'ember-service/inject', 'npm:immutable', 'ember-computed', 'editorium/utils/card'], function (exports, _emberService, _emberEvented, _emberServiceInject, _npmImmutable, _emberComputed, _editoriumUtilsCard) {
  exports['default'] = _emberService['default'].extend(_emberEvented['default'], {
    history: (0, _emberServiceInject['default'])(),

    model: null,

    canUndo: (0, _emberComputed.reads)('history.canUndo'),
    canRedo: (0, _emberComputed.reads)('history.canRedo'),

    init: function init() {
      for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      this._super.apply(this, args);
      this.set('model', []);
    },

    pushObject: function pushObject(object) {
      this.get('model').pushObject(object);
      this.commit();
    },

    reorderItems: function reorderItems(newOrder) {
      this.get('model').setObjects(newOrder);
      this.commit();
    },

    remove: function remove(item) {
      this.get('model').removeObject(item);
    },

    load: function load(items) {
      this.get('model').setObjects(items);
      this.commit();
    },

    append: function append(items) {
      this.get('model').pushObjects(items);
      this.commit();
    },

    insertObjectsAt: function insertObjectsAt(items, index) {
      var model = this.get('model');

      for (var i = 0, len = items.length; i < len; i++) {
        var item = items[i];
        model.insertAt(index + i, item);
      }

      this.commit();
    },

    commit: function commit() {
      var model = this.get('model');
      var immutableModel = _npmImmutable['default'].fromJS(model);
      this.get('history').pushState(immutableModel);
      this.trigger('didUpdate', model);
    },

    amend: function amend() {
      var model = this.get('model');
      var immutableModel = _npmImmutable['default'].List(model);
      this.get('history').replaceState(immutableModel);
      this.trigger('didUpdate', model);
    },

    undo: function undo() {
      var newList = this.get('history').popState().toJS();
      this.restore(newList); // `restore()` will trigger `didUpdate`
    },

    redo: function redo() {
      var newList = this.get('history').redoState().toJS();
      this.restore(newList); // `restore()` will trigger `didUpdate`
    },

    restore: function restore(newList) {
      this.get('model').setObjects(newList);
      this.trigger('didUpdate', newList);
    },

    addItem: function addItem(type, index) {
      var props = arguments.length <= 2 || arguments[2] === undefined ? {} : arguments[2];

      var cardData = (0, _editoriumUtilsCard.buildCard)({
        type: type,
        props: props
      });

      if (index !== null) {
        this.get('model').insertAt(index, cardData);
      } else {
        this.get('model').pushObject(cardData);
      }
    }
  });
});