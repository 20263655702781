define('editorium/components/cards/image-card/component', ['exports', 'editorium/components/card/component', 'editorium/components/cards/image-card/template', 'ember-computed', 'ember-utils', 'ember-runloop'], function (exports, _editoriumComponentsCardComponent, _editoriumComponentsCardsImageCardTemplate, _emberComputed, _emberUtils, _emberRunloop) {
  exports['default'] = _editoriumComponentsCardComponent['default'].extend({
    template: _editoriumComponentsCardsImageCardTemplate['default'],
    classNames: ['image-card'],

    defaultProps: {
      src: null,
      width: 0,
      height: 0,
      mobileSrc: null,
      mobileWidth: 0,
      mobileHeight: 0,
      caption: null,
      showCaption: true,
      alt: null,
      layout: "inset",
      href: null,
      hrefTargetBlank: false
    },

    isEditingMainImage: false,
    isEditingHref: false,

    mainImageSrc: (0, _emberComputed['default'])('props.src', function () {
      var src = this.get('props.src');
      return !(0, _emberUtils.isBlank)(src) ? src : 'http://placehold.it/800x500&text=%3F';
    }),

    mobileImageSrc: (0, _emberComputed['default'])('props.mobileSrc', function () {
      var src = this.get('props.mobileSrc');
      return !(0, _emberUtils.isBlank)(src) ? src : null;
    }),

    shouldShowCaption: (0, _emberComputed['default'])('isFocused', 'props.caption', function () {
      var isFocused = this.get('isFocused');
      var caption = this.get('props.caption');
      return caption || isFocused;
    }),

    actions: {
      selectLayout: function selectLayout(value) {
        this.set('props.layout', value);
        this.commitStore();
      },

      // Main Image
      editMainImage: function editMainImage() {
        this.set('isEditingMainImage', true);
      },

      updateMainImage: function updateMainImage(_ref) {
        var src = _ref.src;
        var width = _ref.width;
        var height = _ref.height;

        this.setErrorState(false);
        this.set('props.src', src);
        this.set('props.width', width);
        this.set('props.height', height);
        this.send('dismissMainImageModal');
        this.commitStore();
      },

      dismissMainImageModal: function dismissMainImageModal() {
        var isBlankSrc = (0, _emberUtils.isBlank)(this.get('props.src'));

        if (isBlankSrc) {
          this.send('removeCard');
        } else {
          this.set('isEditingMainImage', false);
        }
      },

      // Mobile Image
      editMobileImage: function editMobileImage() {
        this.set('isEditingMobileImage', true);
      },

      updateMobileImage: function updateMobileImage(_ref2) {
        var src = _ref2.src;
        var width = _ref2.width;
        var height = _ref2.height;

        this.set('props.mobileSrc', src);
        this.set('props.mobileWidth', width);
        this.set('props.mobileHeight', height);
        this.send('dismissMobileImageModal');
        this.commitStore();
      },

      dismissMobileImageModal: function dismissMobileImageModal() {
        this.set('isEditingMobileImage', false);
      },

      // Caption
      onEditCaption: function onEditCaption() {
        var _this = this;

        (0, _emberRunloop.next)(function () {
          _this.send('focus');
        });
      },

      updateCaption: function updateCaption(value) {
        this.set('props.caption', value);
        this.commitStore();
      },

      // Alt
      onEditAlt: function onEditAlt() {
        var _this2 = this;

        (0, _emberRunloop.next)(function () {
          _this2.send('focus');
        });
      },

      updateAlt: function updateAlt(value) {
        this.set('props.alt', value);
        this.commitStore();
      },

      // Href
      editHref: function editHref() {
        var bool = arguments.length <= 0 || arguments[0] === undefined ? true : arguments[0];

        this.set('isEditingHref', bool);
      },

      updateHref: function updateHref(value) {
        this.set('props.href', value);
        this.send('editHref', false);
        this.commitStore();
      },

      hrefTargetChanged: function hrefTargetChanged(checked) {
        console.log(checked);
      }
    },

    didBecameEditing: function didBecameEditing() {
      this.send('editMainImage');
    },

    initNewCard: function initNewCard() {
      this.send('editMainImage');
    }
  });
});