define('editorium/controllers/application', ['exports', 'ember-controller', 'ember-service/inject', 'ember-computed'], function (exports, _emberController, _emberServiceInject, _emberComputed) {
  exports['default'] = _emberController['default'].extend({
    ui: (0, _emberServiceInject['default'])(),

    isChrome: (0, _emberComputed['default'])(function () {
      // http://stackoverflow.com/a/10251195/1763448
      return (/Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)
      );
    })
  });
});