define("editorium/components/cards/video-card/utils", ["exports"], function (exports) {
  exports.extractId = extractId;
  /*
    Youtube URL pattern CoffeeScript source:
  
    ///
        ^               # Begin
        (?:https?://)?  # Optional scheme. Either http or https
        (?:www\.)?      # Optional www subdomain
        (?:             # Group host alternatives
          youtu\.be/    # Either youtu.be,
        | youtube\.com  # or youtube.com
          (?:           # Group path alternatives
            /embed/     # Either /embed/
          | /v/         # or /v/
          | /watch\?v=  # or /watch\?v=
          )             # End path alternatives.
        )               # End host alternatives.
        ([\w-]{10,12})  # Allow 10-12 for 11 char youtube id.
        $               # End
    ///
  */

  var YOUTUBE_URL_PATTERN = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com(?:\/embed\/|\/v\/|\/watch\?v=))([\w-]{10,12})$/;

  function extractId(str) {
    if (!str) {
      return;
    }

    if (str.match(/^[\w-]{10,12}$/)) {
      return str; // The string is already a YouTube ID
    }

    var match = str.match(YOUTUBE_URL_PATTERN);

    if (match) {
      return match[1];
    }
  }
});