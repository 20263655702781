define("editorium/components/cards/video-card/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.4.6",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 26,
                      "column": 16
                    },
                    "end": {
                      "line": 41,
                      "column": 16
                    }
                  },
                  "moduleName": "editorium/components/cards/video-card/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("\n                  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n\n                  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("button");
                  dom.setAttribute(el1, "class", "btn btn-primary");
                  var el2 = dom.createTextNode("\n                    Save\n                  ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element7 = dom.childAt(fragment, [3]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  morphs[1] = dom.createAttrMorph(element7, 'onclick');
                  return morphs;
                },
                statements: [["inline", "focus-input", [], ["value", ["subexpr", "@mut", [["get", "content.value", ["loc", [null, [31, 26], [31, 39]]]]], [], []], "escape-press", ["subexpr", "action", ["doneEditingValue"], [], ["loc", [null, [32, 33], [32, 60]]]], "insert-newline", ["subexpr", "action", ["updateValue", ["get", "content.value", ["loc", [null, [33, 57], [33, 70]]]]], [], ["loc", [null, [33, 35], [33, 71]]]], "class", "videoCard_valueInput", "placeholder", "Enter a YouTube URL or ID"], ["loc", [null, [30, 18], [36, 20]]]], ["attribute", "onclick", ["subexpr", "action", ["updateValue", ["get", "content.value", ["loc", [null, [38, 57], [38, 70]]]]], [], ["loc", [null, [38, 34], [38, 72]]]]]],
                locals: [],
                templates: []
              };
            })();
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.4.6",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 25,
                    "column": 14
                  },
                  "end": {
                    "line": 42,
                    "column": 14
                  }
                },
                "moduleName": "editorium/components/cards/video-card/template.hbs"
              },
              isEmpty: false,
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "click-outside", [], ["action", ["subexpr", "action", ["updateValue", ["get", "content.value", ["loc", [null, [27, 47], [27, 60]]]]], [], ["loc", [null, [27, 25], [27, 61]]]], "class", "videoCard_valueFormWrapper"], 0, null, ["loc", [null, [26, 16], [41, 34]]]]],
              locals: ["content"],
              templates: [child0]
            };
          })();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.4.6",
              "loc": {
                "source": null,
                "start": {
                  "line": 24,
                  "column": 12
                },
                "end": {
                  "line": 43,
                  "column": 12
                }
              },
              "moduleName": "editorium/components/cards/video-card/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "buffered-proxy", [], ["content", ["subexpr", "@mut", [["get", "props", ["loc", [null, [25, 40], [25, 45]]]]], [], []]], 0, null, ["loc", [null, [25, 14], [42, 33]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.4.6",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 48,
                    "column": 14
                  },
                  "end": {
                    "line": 52,
                    "column": 14
                  }
                },
                "moduleName": "editorium/components/cards/video-card/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("a");
                dom.setAttribute(el1, "class", "videoCard_editButton");
                dom.setAttribute(el1, "target", "_blank");
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element5 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createAttrMorph(element5, 'href');
                morphs[1] = dom.createMorphAt(element5, 1, 1);
                return morphs;
              },
              statements: [["attribute", "href", ["concat", ["https://www.youtube.com/watch?v=", ["get", "videoId", ["loc", [null, [49, 88], [49, 95]]]]]]], ["inline", "fa-icon", ["external-link"], [], ["loc", [null, [50, 18], [50, 45]]]]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.4.6",
              "loc": {
                "source": null,
                "start": {
                  "line": 43,
                  "column": 12
                },
                "end": {
                  "line": 53,
                  "column": 12
                }
              },
              "moduleName": "editorium/components/cards/video-card/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("button");
              dom.setAttribute(el1, "class", "videoCard_editButton");
              dom.setAttribute(el1, "title", "Edit");
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element6 = dom.childAt(fragment, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createAttrMorph(element6, 'onclick');
              morphs[1] = dom.createMorphAt(element6, 1, 1);
              morphs[2] = dom.createMorphAt(fragment, 3, 3, contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["attribute", "onclick", ["subexpr", "action", ["editValue"], [], ["loc", [null, [44, 59], [44, 81]]]]], ["inline", "fa-icon", ["pencil"], [], ["loc", [null, [45, 16], [45, 36]]]], ["block", "if", [["get", "videoId", ["loc", [null, [48, 20], [48, 27]]]]], [], 0, null, ["loc", [null, [48, 14], [52, 21]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 23,
                "column": 10
              },
              "end": {
                "line": 54,
                "column": 10
              }
            },
            "moduleName": "editorium/components/cards/video-card/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "isEditingValue", ["loc", [null, [24, 18], [24, 32]]]]], [], 0, 1, ["loc", [null, [24, 12], [53, 19]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 57,
                "column": 8
              },
              "end": {
                "line": 63,
                "column": 8
              }
            },
            "moduleName": "editorium/components/cards/video-card/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("select");
            dom.setAttribute(el1, "class", "videoCard_selectStyle");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("option");
            dom.setAttribute(el2, "value", "default");
            var el3 = dom.createTextNode("Centered");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("option");
            dom.setAttribute(el2, "value", "outsetRight");
            var el3 = dom.createTextNode("Outset right");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("option");
            dom.setAttribute(el2, "value", "outsetLeft");
            var el3 = dom.createTextNode("Outset left");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            var element2 = dom.childAt(element1, [1]);
            var element3 = dom.childAt(element1, [3]);
            var element4 = dom.childAt(element1, [5]);
            var morphs = new Array(4);
            morphs[0] = dom.createAttrMorph(element1, 'onchange');
            morphs[1] = dom.createAttrMorph(element2, 'selected');
            morphs[2] = dom.createAttrMorph(element3, 'selected');
            morphs[3] = dom.createAttrMorph(element4, 'selected');
            return morphs;
          },
          statements: [["attribute", "onchange", ["subexpr", "action", ["selectStyle"], ["value", "target.value"], ["loc", [null, [58, 57], [58, 102]]]]], ["attribute", "selected", ["subexpr", "eq", [["get", "props.style", ["loc", [null, [59, 50], [59, 61]]]], "default"], [], ["loc", [null, [59, 45], [59, 73]]]]], ["attribute", "selected", ["subexpr", "eq", [["get", "props.style", ["loc", [null, [60, 54], [60, 65]]]], "outsetRight"], [], ["loc", [null, [60, 49], [60, 81]]]]], ["attribute", "selected", ["subexpr", "eq", [["get", "props.style", ["loc", [null, [61, 53], [61, 64]]]], "outsetLeft"], [], ["loc", [null, [61, 48], [61, 79]]]]]],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.4.6",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 68,
                    "column": 10
                  },
                  "end": {
                    "line": 74,
                    "column": 10
                  }
                },
                "moduleName": "editorium/components/cards/video-card/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "focus-input", [], ["value", ["subexpr", "@mut", [["get", "content.caption", ["loc", [null, [70, 20], [70, 35]]]]], [], []], "class", "videoCard_captionInput", "insert-newlink", ["subexpr", "action", ["updateCaption", ["get", "content.caption", ["loc", [null, [72, 53], [72, 68]]]]], [], ["loc", [null, [72, 29], [72, 69]]]]], ["loc", [null, [69, 12], [73, 14]]]]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.4.6",
              "loc": {
                "source": null,
                "start": {
                  "line": 67,
                  "column": 8
                },
                "end": {
                  "line": 75,
                  "column": 8
                }
              },
              "moduleName": "editorium/components/cards/video-card/template.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "click-outside", [], ["action", ["subexpr", "action", ["updateCaption", ["get", "content.caption", ["loc", [null, [68, 58], [68, 73]]]]], [], ["loc", [null, [68, 34], [68, 74]]]]], 0, null, ["loc", [null, [68, 10], [74, 28]]]]],
            locals: ["content"],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 66,
                "column": 6
              },
              "end": {
                "line": 76,
                "column": 6
              }
            },
            "moduleName": "editorium/components/cards/video-card/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "buffered-proxy", [], ["content", ["subexpr", "@mut", [["get", "props", ["loc", [null, [67, 34], [67, 39]]]]], [], []]], 0, null, ["loc", [null, [67, 8], [75, 27]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child3 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.4.6",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 80,
                    "column": 14
                  },
                  "end": {
                    "line": 82,
                    "column": 14
                  }
                },
                "moduleName": "editorium/components/cards/video-card/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("strong");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                return morphs;
              },
              statements: [["content", "props.caption", ["loc", [null, [81, 24], [81, 41]]]]],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.4.6",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 82,
                    "column": 14
                  },
                  "end": {
                    "line": 84,
                    "column": 14
                  }
                },
                "moduleName": "editorium/components/cards/video-card/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("em");
                var el2 = dom.createTextNode("Click to edit caption");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.4.6",
              "loc": {
                "source": null,
                "start": {
                  "line": 77,
                  "column": 8
                },
                "end": {
                  "line": 87,
                  "column": 8
                }
              },
              "moduleName": "editorium/components/cards/video-card/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "videoCard_caption");
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("            ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1, 1]);
              var morphs = new Array(2);
              morphs[0] = dom.createAttrMorph(element0, 'onclick');
              morphs[1] = dom.createMorphAt(element0, 1, 1);
              return morphs;
            },
            statements: [["attribute", "onclick", ["subexpr", "action", ["editCaption"], [], ["loc", [null, [79, 26], [79, 50]]]]], ["block", "if", [["get", "props.caption", ["loc", [null, [80, 20], [80, 33]]]]], [], 0, 1, ["loc", [null, [80, 14], [84, 21]]]]],
            locals: [],
            templates: [child0, child1]
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 76,
                "column": 6
              },
              "end": {
                "line": 88,
                "column": 6
              }
            },
            "moduleName": "editorium/components/cards/video-card/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "shouldShowCaption", ["loc", [null, [77, 14], [77, 31]]]]], [], 0, null, ["loc", [null, [77, 8], [87, 15]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.6",
          "loc": {
            "source": null,
            "start": {
              "line": 8,
              "column": 2
            },
            "end": {
              "line": 90,
              "column": 2
            }
          },
          "moduleName": "editorium/components/cards/video-card/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "videoCard_previewPlayIcon");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "videoCard_editingControls");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "videoCard_removeCardButton");
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element8 = dom.childAt(fragment, [1]);
          var element9 = dom.childAt(element8, [1]);
          var element10 = dom.childAt(element9, [5]);
          var morphs = new Array(8);
          morphs[0] = dom.createAttrMorph(element8, 'class');
          morphs[1] = dom.createAttrMorph(element8, 'onclick');
          morphs[2] = dom.createAttrMorph(element9, 'class');
          morphs[3] = dom.createMorphAt(element9, 3, 3);
          morphs[4] = dom.createMorphAt(dom.childAt(element10, [1]), 1, 1);
          morphs[5] = dom.createMorphAt(element10, 3, 3);
          morphs[6] = dom.createMorphAt(element9, 7, 7);
          morphs[7] = dom.createMorphAt(element8, 3, 3);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", ["videoCard_container--", ["get", "props.style", ["loc", [null, [9, 39], [9, 50]]]]]]], ["attribute", "onclick", ["subexpr", "action", ["focus"], [], ["loc", [null, [9, 62], [9, 80]]]]], ["attribute", "class", ["concat", ["videoCard_previewWrapper ", ["subexpr", "if", [["get", "isFocused", ["loc", [null, [10, 48], [10, 57]]]], "_is_focused"], [], ["loc", [null, [10, 43], [10, 73]]]]]]], ["inline", "aspect-ratio-image", [], ["src", ["subexpr", "@mut", [["get", "previewSrc", ["loc", [null, [13, 14], [13, 24]]]]], [], []], "width", 480, "height", 360], ["loc", [null, [12, 8], [16, 10]]]], ["inline", "remove-card-button", [], ["target", ["subexpr", "@mut", [["get", "this", ["loc", [null, [20, 40], [20, 44]]]]], [], []]], ["loc", [null, [20, 12], [20, 46]]]], ["block", "if", [["get", "isFocused", ["loc", [null, [23, 16], [23, 25]]]]], [], 0, null, ["loc", [null, [23, 10], [54, 17]]]], ["block", "if", [["get", "isFocused", ["loc", [null, [57, 14], [57, 23]]]]], [], 1, null, ["loc", [null, [57, 8], [63, 15]]]], ["block", "if", [["get", "isEditingCaption", ["loc", [null, [66, 12], [66, 28]]]]], [], 2, 3, ["loc", [null, [66, 6], [88, 13]]]]],
        locals: [],
        templates: [child0, child1, child2, child3]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type", "multiple-nodes"]
        },
        "revision": "Ember@2.4.6",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 92,
            "column": 0
          }
        },
        "moduleName": "editorium/components/cards/video-card/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "videoCard");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "videoCard_reorder");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element11 = dom.childAt(fragment, [2]);
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(dom.childAt(element11, [1]), 1, 1);
        morphs[2] = dom.createMorphAt(element11, 3, 3);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "add-cards", [], ["index", ["subexpr", "@mut", [["get", "index", ["loc", [null, [1, 18], [1, 23]]]]], [], []]], ["loc", [null, [1, 0], [1, 25]]]], ["inline", "reorder-card-button", [], ["target", ["subexpr", "@mut", [["get", "this", ["loc", [null, [5, 33], [5, 37]]]]], [], []]], ["loc", [null, [5, 4], [5, 39]]]], ["block", "click-outside", [], ["action", ["subexpr", "action", ["blur"], [], ["loc", [null, [8, 26], [8, 41]]]]], 0, null, ["loc", [null, [8, 2], [90, 20]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});