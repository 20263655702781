define('editorium/components/preview-result/component', ['exports', 'ember-component', 'editorium/components/preview-result/template', 'ember-computed'], function (exports, _emberComponent, _editoriumComponentsPreviewResultTemplate, _emberComputed) {
  exports['default'] = _emberComponent['default'].extend({
    layout: _editoriumComponentsPreviewResultTemplate['default'],

    classNames: ['preview-result'],
    model: null,

    iframeSrc: (0, _emberComputed['default'])('model', function () {
      return 'data:text/html;charset=utf-8,' + encodeURI(this.get('model'));
    })
  });
});