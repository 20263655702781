define('editorium/app', ['exports', 'ember', 'editorium/resolver', 'ember-load-initializers', 'editorium/config/environment', 'jquery'], function (exports, _ember, _editoriumResolver, _emberLoadInitializers, _editoriumConfigEnvironment, _jquery) {

  var App;

  window.CKEDITOR_BASEPATH = _editoriumConfigEnvironment['default'].ckeditorBasePath;
  var script = document.createElement('script');
  script.type = "text/javascript";
  script.src = window.CKEDITOR_BASEPATH + "ckeditor.js";
  document.getElementsByTagName("head")[0].appendChild(script);

  _ember['default'].MODEL_FACTORY_INJECTIONS = true;

  App = _ember['default'].Application.extend({
    modulePrefix: _editoriumConfigEnvironment['default'].modulePrefix,
    podModulePrefix: _editoriumConfigEnvironment['default'].podModulePrefix,
    Resolver: _editoriumResolver['default'],

    customEvents: {
      paste: "paste"
    },

    ready: function ready() {
      // Prevent accidental backspace key to navigate back
      (0, _jquery['default'])(window).on('keydown', function (e) {
        if (e.keyCode === 8 && e.target.nodeName.toLowerCase() === 'body') {
          e.preventDefault();
        }
      });
    }
  });

  (0, _emberLoadInitializers['default'])(App, _editoriumConfigEnvironment['default'].modulePrefix);

  exports['default'] = App;
});