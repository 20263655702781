define('editorium/utils/card', ['exports', 'ember-metal/get'], function (exports, _emberMetalGet) {
  var MOBILEDOC_VERSION = "0.2.0";
  exports.MOBILEDOC_VERSION = MOBILEDOC_VERSION;
  exports.buildCard = buildCard;
  exports.serializeCardsToMobileDoc = serializeCardsToMobileDoc;
  exports.buildCardsFromMobileDoc = buildCardsFromMobileDoc;
  exports.generateEmptyMobileDoc = generateEmptyMobileDoc;
  exports.isValidMobileDoc = isValidMobileDoc;

  function buildCard(_ref) {
    var type = _ref.type;
    var data = _ref.data;

    var componentName = 'components/cards/' + type + '-card';
    var props = data || {};
    var meta = {};

    return {
      type: type,
      componentName: componentName,
      props: props,
      meta: meta
    };
  }

  function serializeCardsToMobileDoc(model) {
    var serializedSections = model.filter(function (section) {
      return !(0, _emberMetalGet['default'])(section, 'meta.isError');
    }).map(function (section) {
      var cardName = section.type;
      var payload = section.props;

      return [10, cardName, payload];
    });

    return JSON.stringify({
      version: MOBILEDOC_VERSION,
      sections: [[], serializedSections]
    });
  }

  function generateEmptyMobileDoc() {
    return {
      "version": "0.2.0",
      "sections": [[], []]
    };
  }

  function isValidMobileDoc(mobileDoc) {
    return !!(mobileDoc && mobileDoc.version && mobileDoc.version === "0.2.0" && Array.isArray(mobileDoc.sections) && Array.isArray(mobileDoc.sections[0]) && Array.isArray(mobileDoc.sections[1]));
  }

  function buildCardsFromMobileDoc(doc) {
    if (doc.list) {
      // Support legacy Editorium format
      return _buildCardsFromLegacyFormat(doc.list);
    }

    return doc.sections[1].map(function (section) {
      return buildCard({
        type: section[1],
        data: section[2]
      });
    });
  }

  function _buildCardsFromLegacyFormat(list) {
    return list.map(function (item) {
      return buildCard(item);
    });
  }
});