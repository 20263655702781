define('editorium/services/history', ['exports', 'ember-service', 'ember-computed', 'npm:immutable'], function (exports, _emberService, _emberComputed, _npmImmutable) {
  exports['default'] = _emberService['default'].extend({
    snapshots: null, // Immutable.List
    cursor: -1,

    canUndo: (0, _emberComputed['default'])('cursor', function () {
      return this.get('cursor') > 0;
    }).readOnly(),

    canRedo: (0, _emberComputed['default'])('cursor', function () {
      return this.get('cursor') < this.get('snapshots').count() - 1;
    }).readOnly(),

    init: function init() {
      this.set('snapshots', _npmImmutable['default'].List());
    },

    currentState: function currentState() {
      return this._objectAtCursor();
    },

    pushState: function pushState(newObject) {
      var currentObject = this._objectAtCursor();
      if (_npmImmutable['default'].is(currentObject, newObject)) {
        return;
      }

      var newSnapshots = this._snapshotsUntilCursor().push(newObject);

      this.set('snapshots', newSnapshots);
      this.incrementProperty('cursor');

      return newObject;
    },

    replaceState: function replaceState(newObject) {
      var currentObject = this._objectAtCursor();
      if (_npmImmutable['default'].is(currentObject, newObject)) {
        return;
      }

      var newSnapshots = this._snapshotsUntilCursor().pop().push(newObject);
      this.set('snapshots', newSnapshots);

      return newObject;
    },

    popState: function popState() {
      if (this.get('canUndo')) {
        this.decrementProperty('cursor');
      }

      return this._objectAtCursor();
    },

    redoState: function redoState() {
      if (this.get('canRedo')) {
        this.incrementProperty('cursor');
      }

      return this._objectAtCursor();
    },

    _objectAtCursor: function _objectAtCursor() {
      var cursor = this.get('cursor');
      return this.get('snapshots').get(cursor);
    },

    _snapshotsUntilCursor: function _snapshotsUntilCursor() {
      var cursor = this.get('cursor');
      return this.get('snapshots').slice(0, cursor + 1);
    }
  });
});