define("editorium/components/fa-button/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "modifiers",
          "modifiers": ["action"]
        },
        "revision": "Ember@2.4.6",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 4,
            "column": 0
          }
        },
        "moduleName": "editorium/components/fa-button/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("button");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(4);
        morphs[0] = dom.createAttrMorph(element0, 'class');
        morphs[1] = dom.createAttrMorph(element0, 'title');
        morphs[2] = dom.createElementMorph(element0);
        morphs[3] = dom.createMorphAt(element0, 1, 1);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", ["faButton", ["subexpr", "if", [["get", "inverse", ["loc", [null, [1, 47], [1, 54]]]], "--inverse"], [], ["loc", [null, [1, 42], [1, 68]]]], " ", ["get", "class", ["loc", [null, [1, 71], [1, 76]]]]]]], ["attribute", "title", ["get", "title", ["loc", [null, [1, 88], [1, 93]]]]], ["element", "action", ["click"], [], ["loc", [null, [1, 8], [1, 26]]]], ["inline", "fa-icon", [["get", "icon", ["loc", [null, [2, 12], [2, 16]]]]], [], ["loc", [null, [2, 2], [2, 18]]]]],
      locals: [],
      templates: []
    };
  })());
});