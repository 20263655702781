define("editorium/components/image-selector/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "fragmentReason": {
            "name": "triple-curlies"
          },
          "revision": "Ember@2.4.6",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 34,
              "column": 0
            }
          },
          "moduleName": "editorium/components/image-selector/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "imageSelector");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "imageSelector_introspectiveImage");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "imageSelector_urlInputWrapper");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "imageSelector_actions");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("button");
          dom.setAttribute(el3, "class", "defaultButton");
          var el4 = dom.createTextNode("\n        Cancel\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("button");
          dom.setAttribute(el3, "class", "primaryButton");
          var el4 = dom.createTextNode("\n        Save\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [5]);
          var element2 = dom.childAt(element1, [1]);
          var element3 = dom.childAt(element1, [3]);
          var morphs = new Array(5);
          morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]), 1, 1);
          morphs[1] = dom.createMorphAt(dom.childAt(element0, [3]), 1, 1);
          morphs[2] = dom.createAttrMorph(element2, 'onclick');
          morphs[3] = dom.createAttrMorph(element3, 'onclick');
          morphs[4] = dom.createAttrMorph(element3, 'disabled');
          return morphs;
        },
        statements: [["inline", "introspective-image", [], ["src", ["subexpr", "@mut", [["get", "newSrc", ["loc", [null, [5, 12], [5, 18]]]]], [], []], "width", ["subexpr", "mut", [["get", "width", ["loc", [null, [6, 19], [6, 24]]]]], [], ["loc", [null, [6, 14], [6, 25]]]], "height", ["subexpr", "mut", [["get", "height", ["loc", [null, [7, 20], [7, 26]]]]], [], ["loc", [null, [7, 15], [7, 27]]]]], ["loc", [null, [4, 6], [8, 8]]]], ["inline", "focus-input", [], ["value", ["subexpr", "mut", [["get", "newSrc", ["loc", [null, [13, 19], [13, 25]]]]], [], ["loc", [null, [13, 14], [13, 26]]]], "escape-press", ["subexpr", "action", ["cancel"], [], ["loc", [null, [14, 21], [14, 38]]]], "insert-newline", ["subexpr", "action", ["save"], [], ["loc", [null, [15, 23], [15, 38]]]], "class", "imageSelector_urlInput", "placeholder", "Enter an image URL"], ["loc", [null, [12, 6], [18, 8]]]], ["attribute", "onclick", ["subexpr", "action", ["cancel"], [], ["loc", [null, [22, 44], [22, 63]]]]], ["attribute", "onclick", ["subexpr", "action", ["save"], [], ["loc", [null, [28, 16], [28, 33]]]]], ["attribute", "disabled", ["get", "isInvalid", ["loc", [null, [29, 19], [29, 28]]]]]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type"]
        },
        "revision": "Ember@2.4.6",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 35,
            "column": 0
          }
        },
        "moduleName": "editorium/components/image-selector/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "modal-dialog", [], ["cancel", ["subexpr", "action", ["cancel"], [], ["loc", [null, [1, 23], [1, 40]]]], "insert-newline", ["subexpr", "action", ["save"], [], ["loc", [null, [1, 56], [1, 71]]]]], 0, null, ["loc", [null, [1, 0], [34, 17]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});