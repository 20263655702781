define('editorium/components/icon-grid-item/component', ['exports', 'ember-component', 'editorium/components/icon-grid-item/template'], function (exports, _emberComponent, _editoriumComponentsIconGridItemTemplate) {
  exports['default'] = _emberComponent['default'].extend({
    layout: _editoriumComponentsIconGridItemTemplate['default'],

    item: null,

    actions: {
      itemTypeChanged: function itemTypeChanged(newValue) {
        this.set('item.type', newValue);
      },

      submit: function submit(event) {
        if (this.attrs['on-submit']) {
          this.attrs['on-submit']();
        }
      }
    }
  });
});