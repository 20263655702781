define('editorium/components/copy-card-button/component', ['exports', 'ember-component', 'editorium/utils/card', 'editorium/utils/clipboard'], function (exports, _emberComponent, _editoriumUtilsCard, _editoriumUtilsClipboard) {
  exports['default'] = _emberComponent['default'].extend({
    tagName: '',

    model: null,

    actions: {
      copy: function copy() {
        var model = this.get('model');
        var mobileDoc = (0, _editoriumUtilsCard.serializeCardsToMobileDoc)([model]);

        (0, _editoriumUtilsClipboard.copy)(mobileDoc);

        this.get('flashMessages').success('Data was copied successfully to your clipboard.');
      }
    }
  });
});