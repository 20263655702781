define('editorium/components/app-bar/component', ['exports', 'ember-component', 'ember-service/inject', 'ember-computed', 'editorium/utils/clipboard', 'editorium/utils/card', 'ember-runloop'], function (exports, _emberComponent, _emberServiceInject, _emberComputed, _editoriumUtilsClipboard, _editoriumUtilsCard, _emberRunloop) {
  exports['default'] = _emberComponent['default'].extend({
    config: (0, _emberServiceInject['default'])(),
    ui: (0, _emberServiceInject['default'])(),
    store: (0, _emberServiceInject['default'])(),
    messageChannel: (0, _emberServiceInject['default'])(),

    undoDisabled: (0, _emberComputed.not)('store.canUndo'),
    redoDisabled: (0, _emberComputed.not)('store.canRedo'),

    preview: null,

    actions: {
      preview: function preview() {
        this.sendAction('on-preview');
      },

      clearPreview: function clearPreview() {
        this.sendAction('on-clear-preview');
      },

      close: function close() {
        var _this = this;

        (0, _emberRunloop.next)(function () {
          // Otherwise the card currently editing wouldn't be saved.
          _this.get('messageChannel').closeEditor();
        });
      },

      copyData: function copyData() {
        var data = this.get('store.model');
        var mobileDoc = (0, _editoriumUtilsCard.serializeCardsToMobileDoc)(data);

        (0, _editoriumUtilsClipboard.copy)(mobileDoc);

        this.get('flashMessages').success('Data was copied successfully to your clipboard.');
      }
    }
  });
});