define('editorium/components/cards/wufoo-form-card/component', ['exports', 'editorium/components/card/component', 'ember-utils', 'editorium/components/cards/wufoo-form-card/template'], function (exports, _editoriumComponentsCardComponent, _emberUtils, _editoriumComponentsCardsWufooFormCardTemplate) {
  exports['default'] = _editoriumComponentsCardComponent['default'].extend({
    layout: _editoriumComponentsCardsWufooFormCardTemplate['default'],

    init: function init() {
      this._super.apply(this, arguments);
    },

    defaultProps: {
      userName: null,
      formHash: null
    },

    actions: {
      updateFormSettings: function updateFormSettings(permalink) {
        var parser = document.createElement('a');
        parser.href = permalink;

        var userName = (parser.hostname.match(/^(.+)\.wufoo\.com$/) || [])[1];
        var formHash = (parser.pathname.match(/^\/forms\/([a-zA-Z0-9]+)\/?$/) || [])[1];

        if (!userName || !formHash) {
          return window.alert('Invalid Permanent Link');
        }

        this.setProperties({
          'props.userName': userName,
          'props.formHash': formHash
        });

        this.send('doneEditing');
        this.commitStore();
      }
    },

    initNewCard: function initNewCard() {
      this.send('edit');
    },

    didDoneEditing: function didDoneEditing() {
      if ((0, _emberUtils.isBlank)(this.get('props.userName')) || (0, _emberUtils.isBlank)(this.get('props.formHash'))) {
        this.send('removeCard');
      }
    }
  });
});