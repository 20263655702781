define('editorium/components/cards/media-objects-card/component', ['exports', 'editorium/components/card/component', 'editorium/components/cards/media-objects-card/template', 'ember-utils', 'ember-runloop'], function (exports, _editoriumComponentsCardComponent, _editoriumComponentsCardsMediaObjectsCardTemplate, _emberUtils, _emberRunloop) {
  exports['default'] = _editoriumComponentsCardComponent['default'].extend({
    template: _editoriumComponentsCardsMediaObjectsCardTemplate['default'],

    actions: {
      addMediaObject: function addMediaObject() {
        this.get('props.items').pushObject({});
      },

      removeMediaObject: function removeMediaObject(item) {
        var _this = this;

        (0, _emberRunloop.next)(function () {
          _this.get('props.items').removeObject(item);
        });
      },

      reorderMediaObjects: function reorderMediaObjects(newOrder) {
        this.get('props.items').setObjects(newOrder);
      }
    },

    didDoneEditing: function didDoneEditing() {
      var _this2 = this;

      this.discardEmptyItems();

      if (this.isEmpty()) {
        (0, _emberRunloop.next)(function () {
          _this2.send('removeCard');
        });
      } else {
        this.commitStore();
      }
    },

    initDefaultProps: function initDefaultProps() {
      this._super({
        items: []
      });
    },

    initNewCard: function initNewCard() {
      this.send('addMediaObject');
      this.send('edit');
    },

    discardEmptyItems: function discardEmptyItems() {
      var nonEmptyItems = this.get('props.items').reject(function (item) {
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = Object.keys(item)[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var key = _step.value;

            if (!(0, _emberUtils.isBlank)(item[key])) {
              return false;
            }
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator['return']) {
              _iterator['return']();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }

        return true;
      });

      this.set('props.items', nonEmptyItems);
    },

    isEmpty: function isEmpty() {
      return !this.get('props.items').length;
    }
  });
});