define('editorium/components/introspective-image/component', ['exports', 'ember-component', 'ember-metal/observer', 'ember-evented/on'], function (exports, _emberComponent, _emberMetalObserver, _emberEventedOn) {
  exports['default'] = _emberComponent['default'].extend({
    tagName: '',

    src: null,
    width: 0,
    height: 0,

    imageObject: null,

    setupImageObject: (0, _emberEventedOn['default'])('init', function () {
      var _this = this;

      if (this.get('imageObject') === null) {
        this.set('imageObject', new Image());
      }

      var self = this;
      var imageObject = this.get('imageObject');

      imageObject.onload = function () {
        var width = this.width;
        var height = this.height;

        self.onLoad.call(self, {
          width: width,
          height: height
        });
      };

      imageObject.onerror = function (e) {
        _this.setProperties({
          width: 0,
          height: 0
        });

        _this.onError(e);
      };

      this.srcDidChange();
    }),

    srcDidChange: (0, _emberMetalObserver['default'])('src', function () {
      var imageObject = this.get('imageObject');
      var src = this.get('src');

      if (src) {
        imageObject.src = src;
      }
    }),

    onLoad: function onLoad(_ref) {
      var width = _ref.width;
      var height = _ref.height;

      this.setProperties({ width: width, height: height });

      if (this.attrs['on-load']) {
        this.attrs['on-load']({ width: width, height: height });
      }
    },

    onError: function onError(e) {
      if (this.attrs['on-error']) {
        this.attrs['on-error'](e);
      }
    }
  });
});