define('editorium/services/preview', ['exports', 'ember-service', 'ember-service/inject', 'ember'], function (exports, _emberService, _emberServiceInject, _ember) {
  var reject = _ember['default'].RSVP.reject;
  exports['default'] = _emberService['default'].extend({
    ajax: (0, _emberServiceInject['default'])(),
    endpoint: null,

    request: function request(data) {
      var endpoint = this.get('endpoint');
      if (!endpoint) {
        return reject('No preview endpoint has been configured.');
      }

      return this.get('ajax').request(endpoint, {
        type: 'POST',
        dataType: 'text',
        data: {
          editorium: data
        }
      });
    }
  });
});