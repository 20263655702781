define('editorium/components/cards/paste-mobiledoc-card/component', ['exports', 'editorium/components/card/component', 'editorium/components/cards/paste-mobiledoc-card/template', 'ember-computed', 'ember-utils', 'editorium/utils/card', 'ember-service/inject'], function (exports, _editoriumComponentsCardComponent, _editoriumComponentsCardsPasteMobiledocCardTemplate, _emberComputed, _emberUtils, _editoriumUtilsCard, _emberServiceInject) {
  exports['default'] = _editoriumComponentsCardComponent['default'].extend({
    layout: _editoriumComponentsCardsPasteMobiledocCardTemplate['default'],

    store: (0, _emberServiceInject['default'])(),
    value: null,

    isValid: (0, _emberComputed['default'])('value', function () {
      var value = this.get('value');

      if ((0, _emberUtils.isBlank)(value)) {
        return false;
      }

      try {
        return (0, _editoriumUtilsCard.isValidMobileDoc)(JSON.parse(value));
      } catch (e) {
        return false;
      }
    }),

    actions: {
      insert: function insert() {
        var store = this.get('store');
        var mobileDoc = JSON.parse(this.get('value'));
        var index = store.get('model').indexOf(this.get('model'));
        var cardsData = (0, _editoriumUtilsCard.buildCardsFromMobileDoc)(mobileDoc);

        store.insertObjectsAt(cardsData, index);
        this.send('removeCard');
        store.commit();
      }
    }
  });
});