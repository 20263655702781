define('editorium/components/card-wrapper/component', ['exports', 'ember-component', 'ember-service/inject', 'ember-computed', 'ember-string'], function (exports, _emberComponent, _emberServiceInject, _emberComputed, _emberString) {
  exports['default'] = _emberComponent['default'].extend({
    ui: (0, _emberServiceInject['default'])(),

    type: null,

    humanName: (0, _emberComputed['default'])('type', function () {
      var type = this.get('type');
      var humanNames = {
        'icon-grid': 'Download Resources',
        'media-objects': 'Contests'
      };

      return humanNames[type] || (0, _emberString.capitalize)(type);
    }),

    iconName: (0, _emberComputed['default'])('type', function () {
      var type = this.get('type');
      var iconNames = {
        'text': 'paragraph',
        'image': 'picture-o',
        'video': 'youtube-play',
        'icon-grid': 'th',
        'media-objects': 'th-list'
      };

      return iconNames[type] || 'question-circle';
    })
  });
});
// jshint ignore:line