define('editorium/components/ck-editor/component', ['exports', 'ember', 'editorium/components/ck-editor/template'], function (exports, _ember, _editoriumComponentsCkEditorTemplate) {
  exports['default'] = _ember['default'].Component.extend({
    layout: _editoriumComponentsCkEditorTemplate['default'],

    config: null,

    _editor: null,
    'on-change': null,
    'on-ready': null,

    didInsertElement: function didInsertElement() {
      var _this = this;

      var textarea = this.element.querySelector('.editor');
      var config = this.get('config');
      var editor = this._editor = CKEDITOR.inline(textarea, config);
      editor.on('change', function (e) {
        _this.sendAction('on-change', e.editor.getData());
      });
      editor.on('instanceReady', function (e) {
        _this.sendAction('on-ready');
      });
    },

    willDestroyElement: function willDestroyElement() {
      this._editor.destroy();
      this._editor = null;
    }
  });
});
/* global CKEDITOR */