define('editorium/routes/application', ['exports', 'ember', 'ember-service/inject', 'editorium/utils/card'], function (exports, _ember, _emberServiceInject, _editoriumUtilsCard) {
  exports['default'] = _ember['default'].Route.extend({
    config: (0, _emberServiceInject['default'])(),
    store: (0, _emberServiceInject['default'])(),
    messageChannel: (0, _emberServiceInject['default'])(),

    setupController: function setupController() {
      var config = this.get('config');
      var store = this.get('store');
      var messageChannel = this.get('messageChannel');

      store.on('didUpdate', function (data) {
        var mobileDoc = (0, _editoriumUtilsCard.serializeCardsToMobileDoc)(data);
        messageChannel.update(mobileDoc);
      });

      messageChannel.loadConfig().then(function (userConfig) {
        config.updateConfig(userConfig);
      })['finally'](function () {
        messageChannel.load().then(function (mobileDoc) {
          var items = (0, _editoriumUtilsCard.buildCardsFromMobileDoc)(mobileDoc);
          store.load(items);
        });
      });
    },

    actions: {
      preview: function preview() {
        var _this = this;

        var data = this.get('store.model');
        var mobileDoc = (0, _editoriumUtilsCard.serializeCardsToMobileDoc)(data);

        this.get('preview').request(mobileDoc).then(function (response) {
          _this.controllerFor('application').set('preview', response);
        })['catch'](function (e) {
          window.alert(e);
        });
      },

      clearPreview: function clearPreview() {
        this.controllerFor('application').set('preview', null);
      }
    }
  });
});