define('editorium/components/cards/icon-grid-card/component', ['exports', 'editorium/components/card/component', 'editorium/components/cards/icon-grid-card/template', 'ember-utils', 'ember-runloop'], function (exports, _editoriumComponentsCardComponent, _editoriumComponentsCardsIconGridCardTemplate, _emberUtils, _emberRunloop) {
  exports['default'] = _editoriumComponentsCardComponent['default'].extend({
    layout: _editoriumComponentsCardsIconGridCardTemplate['default'],

    availableTypes: {
      'doc': 'Document',
      'image': 'Image',
      'other-text': 'Text',
      'pdf': 'PDF',
      'ppt': 'PowerPoint',
      'video': 'Video'
    },

    actions: {
      addItem: function addItem() {
        this.get('props.items').pushObject({
          type: 'pdf',
          label: null,
          url: null
        });
      },

      deleteItem: function deleteItem(item) {
        var _this = this;

        // Must delete the item in the next run loop, because the outside click
        // would trigger for an element that is no longer a child of the
        // component.
        (0, _emberRunloop.next)(function () {
          _this.get('props.items').removeObject(item);
        });
      },

      reorderIconGridItems: function reorderIconGridItems(items) {
        this.get('props.items').setObjects(items);
      }
    },

    didDoneEditing: function didDoneEditing() {
      var _this2 = this;

      this.discardEmptyItems();

      if (this.isEmpty()) {
        (0, _emberRunloop.next)(function () {
          _this2.send('removeCard');
        });
      } else {
        this.commitStore();
      }
    },

    discardEmptyItems: function discardEmptyItems() {
      var filteredItems = this.get('props.items').filter(function (item) {
        return !(0, _emberUtils.isBlank)(item.label) && !(0, _emberUtils.isBlank)(item.url);
      });

      this.set('props.items', filteredItems);
    },

    initDefaultProps: function initDefaultProps() {
      this._super({
        items: []
      });
    },

    initNewCard: function initNewCard() {
      this.send('addItem');
      this.send('edit');
    },

    isEmpty: function isEmpty() {
      return !this.get('props.items').length;
    }
  });
});