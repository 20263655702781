define('editorium/components/editable-alt/component', ['exports', 'ember-component', 'ember-computed'], function (exports, _emberComponent, _emberComputed) {
  exports['default'] = _emberComponent['default'].extend({
    value: null,
    showWhenEmpty: true,
    isEditing: false,

    shouldShowAlt: (0, _emberComputed['default'])('showWhenEmpty', 'value', function () {
      var showWhenEmpty = this.get('showWhenEmpty');
      var alt = this.get('value');
      return alt || showWhenEmpty;
    }),

    actions: {
      edit: function edit() {
        var bool = arguments.length <= 0 || arguments[0] === undefined ? true : arguments[0];

        this.set('isEditing', bool);
        if (this.attrs['on-edit']) {
          this.attrs['on-edit'](bool);
        }
      },

      update: function update(value) {
        this.send('edit', false);
        this.attrs['on-update'](value);
      }
    }
  });
});