define('editorium/services/message-channel', ['exports', 'ember', 'ember-service', 'ember-service/inject', 'npm:jschannel', 'editorium/utils/card'], function (exports, _ember, _emberService, _emberServiceInject, _npmJschannel, _editoriumUtilsCard) {
  var Promise = _ember['default'].RSVP.Promise;
  exports['default'] = _emberService['default'].extend({
    store: (0, _emberServiceInject['default'])(),
    channel: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.initChannel();
    },

    initChannel: function initChannel() {
      var channel = undefined;

      try {
        channel = _npmJschannel['default'].build({
          window: window.parent,
          origin: '*',
          scope: 'editorium'
        });
      } catch (e) {
        channel = {
          call: function call() {}
        };
      }

      this.set('channel', channel);
    },

    loadConfig: function loadConfig() {
      var _this = this;

      return new Promise(function (resolve, reject) {
        _this.get('channel').call({
          method: 'loadConfig',

          success: function success(config) {
            resolve(config);
          },

          error: function error(_error, _message) {
            reject(_error);
          }
        });
      });
    },

    load: function load() {
      var _this2 = this;

      return new Promise(function (resolve, reject) {
        _this2.get('channel').call({
          method: 'load',

          success: function success(result) {
            if (!result) {
              return resolve((0, _editoriumUtilsCard.generateEmptyMobileDoc)());
            }

            var mobileDoc = undefined;
            try {
              mobileDoc = JSON.parse(result);
            } catch (e) {
              return reject(e);
            }

            if (!(0, _editoriumUtilsCard.isValidMobileDoc)(mobileDoc)) {
              return reject('Invalid MobileDoc: ' + mobileDoc);
            }

            resolve(mobileDoc);
          },

          error: function error(_error2, _message) {
            reject(_error2);
          }
        });
      });
    },

    update: function update(mobileDoc) {
      var _this3 = this;

      return new Promise(function (resolve, reject) {
        _this3.get('channel').call({
          method: 'update',
          params: mobileDoc,

          success: function success(result) {
            resolve();
          },

          error: function error(_error3, _message) {
            reject(_error3);
          }
        });
      });
    },

    closeEditor: function closeEditor() {
      var _this4 = this;

      return new Promise(function (resolve, reject) {
        _this4.get('channel').call({
          method: 'close',

          success: function success(result) {
            resolve(result);
          },

          error: function error(_error4, _message) {
            reject(_error4);
          }
        });
      });
    }
  });
});