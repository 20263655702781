define('editorium/components/fa-button/component', ['exports', 'ember-component'], function (exports, _emberComponent) {
  exports['default'] = _emberComponent['default'].extend({
    tagName: '',

    actions: {
      click: function click() {
        this.sendAction();
      }
    }
  });
});