define('editorium/components/card-deprecated/component', ['exports', 'ember-component', 'ember-click-outside/mixins/click-outside', 'jquery', 'ember-service/inject', 'npm:immutable', 'ember-computed', 'ember-runloop', 'ember-utils', 'ember', 'editorium/components/card-deprecated/template'], function (exports, _emberComponent, _emberClickOutsideMixinsClickOutside, _jquery, _emberServiceInject, _npmImmutable, _emberComputed, _emberRunloop, _emberUtils, _ember, _editoriumComponentsCardDeprecatedTemplate) {
  var resolve = _ember['default'].RSVP.resolve;

  /**
    Editorium Card Base Class
  
    @module editorium
    @class EditoriumCard
    @extends Ember.Component
  */
  exports['default'] = _emberComponent['default'].extend(_emberClickOutsideMixinsClickOutside['default'], {
    layout: _editoriumComponentsCardDeprecatedTemplate['default'],
    type: null,

    store: (0, _emberServiceInject['default'])(),

    model: null,
    index: null,

    isEditing: (0, _emberComputed.alias)('model.isEditing'),
    meta: (0, _emberComputed.alias)('model.meta'),
    props: (0, _emberComputed.alias)('model.props'),

    normalPartialName: (0, _emberComputed['default'])('type', function () {
      return 'components/cards/' + this.get('type') + '-card/normal';
    }),
    editingPartialName: (0, _emberComputed['default'])('type', function () {
      return 'components/cards/' + this.get('type') + '-card/editing';
    }),

    init: function init() {
      for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      this._super.apply(this, args);

      if (this.isNew()) {
        this.send('edit');
      }
    },

    actions: {

      removeItem: function removeItem() {
        this.removeFromStore();
        this.commitStore();
      },

      edit: function edit() {
        this.copyPropsToMeta();
        this.willEdit();
        this.set('model.isEditing', true);

        // If `enableClickOutside()` wouldn't be scheduled until the next run
        // loop, the outside click would happen immediately, resulting in the
        // removal of the card right after it was inserted.
        (0, _emberRunloop.next)(this, this.enableClickOutside);
      },

      save: function save() {
        var _this = this;

        this.willSave();

        // If the card is blank, we remove it from the list
        if (this.isBlank()) {
          this.disableClickOutside();
          this.removeFromStore();

          // If it wasn't a newly added card, it's a deletion, henceforth it
          // requires the history to be updated.
          if (!this.isNew()) {
            this.commitStore();
          }

          return;
        }

        // If the data didn't change, just finish editing and don't bother with
        // validation or updating the history.
        if (!this.hasChanges()) {
          this.disableClickOutside();
          this.doneEditing();

          return;
        }

        // If the validation is complete, remove the event handlers, update the
        // props hash, disable editing and update the history.
        this.validate().then(function (result) {
          _this.didValidate(result);
          _this.disableClickOutside();
          _this.copyMetaToProps();
          _this.doneEditing();
          _this.commitStore();
        })['catch'](function (error) {
          _this.validationFailed(error);
        });
      },

      discard: function discard() {
        this.doneEditing();
      }
    },

    commitStore: function commitStore() {
      this.get('store').commit();
    },

    doneEditing: function doneEditing() {
      this.set('model.isEditing', false);
    },

    clickOutside: function clickOutside() {
      this.send('save');
    },

    validate: function validate() {
      return resolve();
    },

    didValidate: function didValidate(result) {
      // Do something with the result inside subclasses.
    },

    validationFailed: function validationFailed(error) {
      // Do something with the error inside subclasses.
    },

    willEdit: function willEdit() {
      // Do something when the card is about to turn into editing mode inside
      // subclasses.
    },

    willSave: function willSave() {
      // Do something when the card is about to turn into editing mode inside
      // subclasses.
    },

    removeFromStore: function removeFromStore() {
      var store = this.get('store');
      store.remove(this.get('model'));
    },

    // By default, the meta is not if any of it's properties are truthy
    isBlank: function isBlank() {
      var meta = this.get('meta');

      for (var key in meta) {
        if (!(0, _emberUtils.isBlank)(meta[key])) {
          return false;
        }
      }

      return true;
    },

    isNew: function isNew() {
      var props = this.get('props');

      for (var key in props) {
        if (hasOwnProperty.call(props, key)) {
          return false;
        }
      }

      return true;
    },

    hasChanges: function hasChanges() {
      return !_npmImmutable['default'].is(_npmImmutable['default'].fromJS(this.get('props')), _npmImmutable['default'].fromJS(this.get('meta')));
    },

    enableClickOutside: function enableClickOutside() {
      this.addClickOutsideListener();
    },

    disableClickOutside: function disableClickOutside() {
      this.removeClickOutsideListener();
    },

    copyPropsToMeta: function copyPropsToMeta() {
      var props = this.get('props');
      var newMeta = _jquery['default'].extend(true, {}, props);

      this.set('meta', newMeta);
    },

    copyMetaToProps: function copyMetaToProps() {
      var meta = this.get('meta');
      var newProps = _jquery['default'].extend(true, {}, meta);

      this.set('props', newProps);
    },

    extractValueFromEvent: function extractValueFromEvent(valueOrEvent) {
      if ((0, _emberUtils.isBlank)(valueOrEvent)) {
        return null;
      }

      if (typeof valueOrEvent === 'string') {
        return valueOrEvent;
      }

      // TODO paste
      return valueOrEvent.target.value;
    },

    willDestroyElement: function willDestroyElement(args) {
      this._super.apply(this, args);
      this.disableClickOutside();
    }
  });
});