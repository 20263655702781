define('editorium/components/image-selector/component', ['exports', 'ember-component', 'ember-computed', 'ember-utils', 'jquery'], function (exports, _emberComponent, _emberComputed, _emberUtils, _jquery) {
  exports['default'] = _emberComponent['default'].extend({
    newSrc: null,

    allowEmpty: false,
    isInvalid: (0, _emberComputed.not)('isValid'),
    isValid: (0, _emberComputed['default'])('width', 'height', 'newSrc', 'allowEmpty', function () {
      var allowEmpty = this.get('allowEmpty');
      var newSrc = this.get('newSrc');

      if (allowEmpty === true && (0, _emberUtils.isBlank)(newSrc)) {
        return true;
      }

      var width = this.get('width');
      var height = this.get('height');

      return width > 0 && height > 0;
    }),

    didReceiveAttrs: function didReceiveAttrs(attrs) {
      this.set('newSrc', attrs.newAttrs.src.value);

      if ('allow-empty' in attrs.newAttrs) {
        this.set('allowEmpty', !!attrs.newAttrs['allow-empty']);
      }
    },

    actions: {
      save: function save() {
        if (this.get('isValid')) {
          this.attrs['on-save']({
            src: _jquery['default'].trim(this.get('newSrc')),
            width: this.get('width'),
            height: this.get('height')
          });
        }
      },

      cancel: function cancel() {
        this.attrs['on-cancel']();
      }
    }
  });
});