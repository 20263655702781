define('editorium/components/add-cards/component', ['exports', 'ember-component', 'ember-service/inject'], function (exports, _emberComponent, _emberServiceInject) {
  exports['default'] = _emberComponent['default'].extend({
    config: (0, _emberServiceInject['default'])(),
    store: (0, _emberServiceInject['default'])(),
    index: null,

    isOpened: false,
    alwaysOpened: false,

    didInitAttrs: function didInitAttrs(_ref) {
      var attrs = _ref.attrs;

      if (attrs.alwaysOpened === true) {
        this.set('isOpened', true);
      }
    },

    actions: {
      open: function open() {
        this.set('isOpened', true);
      },

      close: function close() {
        if (!this.get('alwaysOpened')) {
          this.set('isOpened', false);
        }
      },

      addItem: function addItem(type, index) {
        this.send('close');
        this.get('store').addItem(type, index);
      }
    }
  });
});