define('editorium/components/modal-dialog/component', ['exports', 'ember-modal-dialog/components/modal-dialog', 'ember-evented/on'], function (exports, _emberModalDialogComponentsModalDialog, _emberEventedOn) {
  exports['default'] = _emberModalDialogComponentsModalDialog['default'].extend({
    translucentOverlay: true,
    acceptsKeyResponder: true,

    becomeKeyResponderWhenInserted: (0, _emberEventedOn['default'])('didInsertElement', function () {
      this.becomeKeyResponder();
    }),

    resignKeyResponderWhenDestroyed: (0, _emberEventedOn['default'])('willDestroyElement', function () {
      this.resignKeyResponder();
    }),

    insertNewline: function insertNewline() {
      this.sendAction('insert-newline');
    },

    cancel: function cancel() {
      this.sendAction('cancel');
    }
  });
});