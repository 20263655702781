define('editorium/components/card/component', ['exports', 'ember-component', 'ember-service/inject', 'ember-computed', 'ember-metal/utils', 'ember-platform'], function (exports, _emberComponent, _emberServiceInject, _emberComputed, _emberMetalUtils, _emberPlatform) {
  exports['default'] = _emberComponent['default'].extend({
    store: (0, _emberServiceInject['default'])(),
    ui: (0, _emberServiceInject['default'])(),

    classNames: ['uiCard'],
    classNameBindings: ['isCollapsed:_is_collapsed'],
    isCollapsed: (0, _emberComputed.alias)('ui.isCardsCollapsed'),

    index: null,
    type: null,
    model: null,
    isNew: true, // Will be set to false

    meta: (0, _emberComputed.alias)('model.meta'),
    props: (0, _emberComputed.alias)('model.props'),
    defaultProps: null,

    _isFocused: false,
    _isEditing: false,

    init: function init() {
      this._super.apply(this, arguments);

      if (Object.keys(this.get('props')).length) {
        this.set('isNew', false);
      }
    },

    isFocused: (0, _emberComputed['default'])('_isFocused', '_isEditing', {
      get: function get() {
        return this.get('_isFocused');
      },

      set: function set(key, value) {
        if (value === false) {
          this.set('isEditing', false);
        }

        this.set('_isFocused', value);
        return value;
      }
    }),

    isEditing: (0, _emberComputed['default'])('_isFocused', '_isEditing', {
      get: function get() {
        return this.get('_isFocused') && this.get('_isEditing');
      },

      set: function set(key, value) {
        if (value === true) {
          this.set('_isFocused', true);
        }

        this.set('_isEditing', value);
        return value;
      }
    }),

    actions: {
      focus: function focus() {
        this.set('isFocused', true);
        this.didFocus();
      },

      blur: function blur() {
        this.set('isFocused', false);
        this.didDoneEditing();
        this.didBlur();
      },

      edit: function edit() {
        this.set('isEditing', true);
        this.didFocus();
        this.didBecameEditing();
      },

      doneEditing: function doneEditing() {
        this.set('isEditing', false);
        this.didDoneEditing();
      },

      // DEPRECATED, use the `removeCard` action
      removeItem: function removeItem() {
        this.send('removeCard');
      },

      removeCard: function removeCard() {
        this.removeFromStore();
        this.commitStore();
      }
    },

    // Override in subclasses
    didBecameEditing: function didBecameEditing() {},
    didDoneEditing: function didDoneEditing() {},
    didFocus: function didFocus() {},
    didBlur: function didBlur() {},
    initNewCard: function initNewCard() {},

    didInitAttrs: function didInitAttrs(_ref) {
      var attrs = _ref.attrs;

      this.initDefaultProps();

      // TODO investigate why the below is needed
      if (Object.keys(attrs.model.value.props).length === 0) {
        this.setErrorState(true);
        this.send('edit');
      }

      if (this.get('isNew')) {
        this.initNewCard();
        this.set('isNew', false);
      }
    },

    commitStore: function commitStore() {
      this.get('store').commit();
    },

    removeFromStore: function removeFromStore() {
      var store = this.get('store');
      var model = this.get('model');

      store.remove(model);
    },

    initDefaultProps: function initDefaultProps(defaultProps) {
      defaultProps = (0, _emberMetalUtils.copy)(defaultProps || this.get('defaultProps')) || {};
      var props = this.get('model.props');

      this.set('model.props', (0, _emberPlatform.assign)(defaultProps, props));
    },

    setErrorState: function setErrorState(bool) {
      this.set('model.meta.isError', !!bool);
    }
  });
});